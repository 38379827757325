import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LicenseIsB2cGuard } from './shared/guards/license-is-b2c.guard';
import { HasAccessTokenGuard } from './shared/guards/has-access-token.guard';
import { CreateAccountFirstGuard } from './shared/guards/create-account-first.guard';
import { TenantIdResolverService } from './shared/guards/tenant-id-resolver-service';

const appRoutes = [
    {
        path: '',
        loadChildren: () => import('./modules/access/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'licence',
        loadChildren: () => import('./modules/license/license.module').then((m) => m.LicenseModule),
        canActivate: [HasAccessTokenGuard]
    },
    {
        path: 'payment',
        loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
        canActivate: [CreateAccountFirstGuard]
    },
    {
        path: 'purchase',
        loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule),
        canActivate: [CreateAccountFirstGuard]
    },
    {
        path: 'billing',
        loadChildren: () => import('./modules/billing/billing.module').then((m) => m.BillingModule),
        canActivate: [HasAccessTokenGuard, LicenseIsB2cGuard],
    },
    {
        path: 'download-trial',
        loadChildren: () => import('./modules/trial/trial.module').then((m) => m.TrialModule),
        canActivate: [HasAccessTokenGuard],
    },
    {
        path: 'existing-trial',
        loadChildren: () => import('./modules/trial/trial.module').then((m) => m.TrialModule),
        canActivate: [HasAccessTokenGuard],
    }
];

const routes: Routes = [
    ...appRoutes,
    {
        path: 't/:tenantId',
        // resolve: { tenantId: TenantIdResolverService },
        children: [
            ...appRoutes,
        ]
    },
    {
        path: '**',
        redirectTo: '/start',
    },
//     {
//         path: '',
//         loadChildren: () => import('./modules/select-tenant/select-tenant.module').then((m) => m.SelectTenantModule),
//     },
//     {
//         path: '**',
//         redirectTo: 'tenant-select',
//     }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
    providers: [TenantIdResolverService]
})
export class AppRoutingModule { }
