import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './angular-material.module';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { HasAccessTokenGuard } from './shared/guards/has-access-token.guard';
import { CreateAccountFirstGuard } from './shared/guards/create-account-first.guard';
import { GpPopupDialogModule } from './shared/components/dialogs/gp-popup-dialog/gp-popup-dialog.module';
import { TenantIdService } from './shared/services/tenant-id.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        FormsModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        NgxStripeModule.forRoot(),
        GpPopupDialogModule,
    ],
    providers: [
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        HasAccessTokenGuard,
        CreateAccountFirstGuard,
        TenantIdService
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
