import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, } from '@angular/router';
import { Observable } from 'rxjs';
import { LicenseService } from '../services/license.service';
import { environment as env } from 'src/environments/environment';
import { LocalStorage_AuthToken, PurchaseWithoutTrialIntent, ReturnUrlParameter } from '../constants/constants';
import { TenantIdService } from '../services/tenant-id.service';

@Injectable({
    providedIn: 'root',
})
export class CreateAccountFirstGuard implements CanActivate {
    constructor(
        private router: Router,
        private tenantIdService: TenantIdService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const token = localStorage.getItem(LocalStorage_AuthToken);

        if (token !== null && token !== undefined)
            return true;

        let path = route.pathFromRoot
                        .map(v => v.url.map(segment => segment.toString()).join('/'))
                        .join('/');

        if (route.queryParams && Object.keys(route.queryParams).length > 0) {
            path += '?' + Object.keys(route.queryParams).map(key => key + '=' + route.queryParams[key]).join('&');
        }

        this.tenantIdService.tenantId.subscribe(cfg => this.router.navigateByUrl(`${cfg.path}/start?${ReturnUrlParameter}=${encodeURIComponent(path)}&intent=${PurchaseWithoutTrialIntent}`));
        // const tenantId = route.paramMap.get('tenantId');
        
        return false;
    }
}
