import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { TenantIdentifierInfo, TenantInfoSource } from "../interfaces/tenant.interfaces";

@Injectable()
export class TenantIdService {

  public tenantIdentifier: ReplaySubject<TenantIdentifierInfo> = new ReplaySubject<TenantIdentifierInfo>(1); //{ source: 'notset', name: ''}

  constructor() { 
  }

  public get tenantId(): Observable<TenantIdentifierInfo> {
    return this.tenantIdentifier;
  }

  public setTenantId(tenantName: string, source: TenantInfoSource){
    const tenantIdPath = source == 'path' ? '/t/' + tenantName : '';
    this.tenantIdentifier.next({ name: tenantName ?? '', source, path: tenantIdPath });
    this.tenantIdentifier.complete();
  }
}
