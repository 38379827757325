import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorage_AuthToken } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class HasAccessTokenGuard implements CanActivate {
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const token = localStorage.getItem(LocalStorage_AuthToken);

        if (token !== null && token !== undefined)
            return true;

        this.router.navigate(['/start']);
        return false;
    }
}
