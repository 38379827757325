export const BearerAuthSchema = 'Bearer';

export const LocalStorage_AuthToken = 'Access';
export const LocalStorage_RefreshToken = 'Refresh';
export const LocalStorage_AuthProvider = 'AuthUrl';
export const LocalStorage_Auth_Provider_Prefix = 'AuthUrl_';
export const LocalStorage_TenantName = 'Tenant';
export const LocalStorage_ExpiresAt = 'Exp';

export const LicenseType_B2c = 'b2c';
export const LicenseType_B2b = 'b2b';

export const AuthInterceptorExcludeUrls = ['/auth/otp', '/auth/login', 'keycloak.define.local', 'id.app'];

export const LicenseStatusTrial = "trial";
export const LicenseStatusTrialExpired = "trial_expired";
export const PurchaseWithoutTrialIntent = 'purchase_no_trial';
export const ReturnUrlParameter = 'return';

export const LicenseManagerClientId = 'b2x-license-manager'