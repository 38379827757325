import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, } from '@angular/router';
import { Observable } from 'rxjs';
import { LicenseService } from '../services/license.service';
import { environment as env } from 'src/environments/environment';
import { TenantIdService } from '../services/tenant-id.service';

@Injectable({
    providedIn: 'root',
})
export class LicenseIsB2cGuard implements CanActivate {
    constructor(
        private licenseService: LicenseService,
        private router: Router,
        private tenantIdService: TenantIdService
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.licenseService.isB2c()) {
            if (env.debugOutputs)
                console.log('License is b2c');

            return true;
        } else {
            if (env.debugOutputs)
                console.log('License is not b2c');

            this.tenantIdService.tenantId.subscribe(cfg => this.router.navigate([cfg.path, 'licence']));
            //const tenantId = route.paramMap.get('tenantId');
            
            return false;
        }
    }
}
