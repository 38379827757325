import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TenantIdService } from './shared/services/tenant-id.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { TenantInfoSource } from './shared/interfaces/tenant.interfaces';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'define-front';
    subdomainRegex = new RegExp('^([a-z0-9\-]+)\.licence');

    constructor(
        // private activatedRoute: ActivatedRoute,
        private router: Router,
        private tenantIdService: TenantIdService,
        private configService: ConfigurationService
    ) { }

    ngOnInit(): void {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                const mergedParams = this.mergeRouteParams(this.router);

                let domain = window.location.hostname;
                let matchResult = this.subdomainRegex.exec(domain);

                let subdomainTenantName = matchResult?.[1];

                let tenantName = mergedParams['tenantId'] ?? subdomainTenantName;
                let source: TenantInfoSource = mergedParams['tenantId'] 
                    ? 'path'
                    : (subdomainTenantName 
                        ? 'subdomain'
                        : 'notset');
                
                if (source == 'notset' || tenantName == '') {
                    this.router.navigate(['/t/definely/']);
                    return;
                }

                this.tenantIdService.setTenantId(tenantName ?? '', source);
    
                this.configService.getAppConfig()
                    .subscribe(r => {
                        // console.log(r);
                    });
            }
        });
    }

    mergeRouteParams(router: Router): { [key: string]: string } {
        let params = {};
        let route = router.routerState.snapshot.root;
        
        do {
            params = { ...params, ...route.params };
            route = route.firstChild;
        } while (route);

        return params;
    }
}
