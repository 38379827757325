import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { map, switchMap, tap } from 'rxjs/operators';
import { LocalStorage_RefreshToken, LocalStorage_AuthToken, LocalStorage_ExpiresAt, LicenseManagerClientId } from '../constants/constants';
import { ConfigurationService } from './configuration.service';
import { AccessTokenResponse } from '../interfaces/auth.interfaces';

@Injectable({
    providedIn: 'root',
})
export class AccessCodeService {
    constructor(
        private httpClient: HttpClient,
        private configService: ConfigurationService,
    ) {
    }

    public refreshToken() {
        return this.configService.getAppConfig()
            .pipe(
                switchMap(cfg => {
                    var refreshToken = localStorage.getItem(LocalStorage_RefreshToken);
                    var payload = { 
                        'grant_type': 'refresh_token', 
                        'client_id': LicenseManagerClientId, 
                        'refresh_token': refreshToken};

                    return this.postForm<AccessTokenResponse>(cfg.authProviderUrl + '/protocol/openid-connect/token', payload);
                }),
                tap(result => {
                    localStorage.setItem(LocalStorage_AuthToken, result.access_token);
                    localStorage.setItem(LocalStorage_RefreshToken, result.refresh_token);
                    localStorage.setItem(LocalStorage_ExpiresAt, (Date.now() + result.expires_in).toString());
                })
            );
    }

    public clearToken(): void {
        localStorage.removeItem(LocalStorage_AuthToken);
        localStorage.removeItem(LocalStorage_RefreshToken);
        localStorage.removeItem(LocalStorage_ExpiresAt);
    }

    public isTokenExpired(): boolean {
        const token = this.getToken();
        if (!token) {
            return false;
        }
        const date = this.getDataFromToken(token);
        if (!date) {
            return false;
        }

        return date < Date.now();
    }

    private getDataFromToken(token: string): number {
        try {
            const decoded = jwt_decode(token);
            return decoded['exp'] * 1000;
        }
        catch {
            return 0;
        }
    }

    public getToken(): string {
        return localStorage.getItem(LocalStorage_AuthToken);
    }

    public getRefresh(): string {
        return localStorage.getItem(LocalStorage_RefreshToken);
    }

    public convertCode(code: string, sessionState: string, redirectUri: string) {
        const body = { 
            grant_type: 'authorization_code',
            client_id: LicenseManagerClientId,
            code, 
            sessionState, 
            redirect_uri: redirectUri };

        return this.configService.getAppConfig()
            .pipe(
                switchMap(cfg => this.postForm<AccessTokenResponse>(`${cfg.authProviderUrl}/protocol/openid-connect/token`, body)),
                tap(result => {
                    if (result.access_token) {
                        localStorage.setItem(LocalStorage_AuthToken, result.access_token);
                        localStorage.setItem(LocalStorage_RefreshToken, result.refresh_token);
                        localStorage.setItem(LocalStorage_ExpiresAt, (Date.now() + result.expires_in).toString());
                    }
                })
            );
    }

    private postForm<T>(url: string, payload: Record<string, string>) {
        const headers = new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded');
        let body = new URLSearchParams(payload);

        return this.httpClient.post<T>(url, body.toString(), { headers })
    }
}
