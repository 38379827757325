import { HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BearerAuthSchema, AuthInterceptorExcludeUrls } from '../constants/constants';
import { AccessCodeService } from '../services/access-code.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    private canRefresh = true;

    constructor(private accessCodeService: AccessCodeService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.accessCodeService.isTokenExpired() && this.canRefresh) {
            if (AuthInterceptorExcludeUrls.find((url) => req.url.includes(url))) {
                return next.handle(req);
            }
            
            this.canRefresh = false;
            this.accessCodeService
                .refreshToken()
                .subscribe(_ => this.canRefresh = true);
        }
        
        const token = this.accessCodeService.getToken();

        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: BearerAuthSchema + ' ' + token,
                },
            });
        }

        return next.handle(req);
    }
}
