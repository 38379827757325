import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environmentLoader, setupHttpEnvironment } from './environments/environment-loader'
import { AppVersion } from './environments/app-version';

environmentLoader
    .then(httpEnvironment => setupHttpEnvironment(environment, httpEnvironment))
    .then(httpEnvironment => {
        Sentry.init({
            release: AppVersion,
            environment: environment.sentryEnvironment,
            dsn: environment.sentryDsn,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            // enableTracing: false,
            tracesSampleRate: 0.1, //  Capture 10% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [],
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        return httpEnvironment;
    })
    .then(_ => {
        if (environment.production) {
            enableProdMode();
        }

        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch(err => console.error(err));
    })

