import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { TenantIdService } from "./tenant-id.service";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment as env } from 'src/environments/environment';


export abstract class BaseService {
    tenantUrl: Observable<string>;

    constructor(
        protected tenantIdService: TenantIdService,
        private httpClient: HttpClient,
    ) {

        this.tenantUrl = tenantIdService.tenantId
            .pipe(
                map(tenantInfo => 
                    (tenantInfo.name != '' && tenantInfo.source == 'subdomain')
                    ? 'https://' + tenantInfo.name + '.' + env.apiUrlPartial
                    : 'https://' + env.apiUrlPartial)
            );
    }

    protected getHeaders() {
        return this.tenantIdService.tenantId
            .pipe(
                map(tenantInfo => new HttpHeaders().append('X-Tenant', tenantInfo.name))
            );
    }

    protected getDefaultOptions() {
        return this.getHeaders()
            .pipe(
                map(headers => ({ headers }))
            );
    }

    protected apiPost<T>(url: string, payload: any) {
        return this.getDefaultOptions()
            .pipe(
                switchMap(options => this.httpClient.post<T>(url, payload, options))
            );
    }

    protected apiGet<T>(url: string, params?: HttpParams) {
        return this.getDefaultOptions()
            .pipe(
                switchMap(options => this.httpClient.get<T>(url, { params, ...options }))
            );
    }

    protected apiGetWithResponse<T>(url: string, params?: HttpParams): Observable<HttpResponse<T>> {
        return this.getDefaultOptions()
            .pipe(
                switchMap(options => this.httpClient.get<T>(url, { params, observe: 'response', ...options }))
            );
    }

    protected apiPut<T>(url: string, payload: T) {
        return this.getDefaultOptions()
            .pipe(
                switchMap(options => this.httpClient.put<T>(url, payload, options))
            );
    }

    protected apiDelete(url: string) {
        return this.getDefaultOptions()
            .pipe(
                switchMap(options => this.httpClient.delete(url, options))
            );
    }
}
