import { ChangeDetectionStrategy, Component, Inject, OnInit, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'gp-popup-dialog',
    templateUrl: './gp-popup-dialog.component.html',
    styleUrls: ['./gp-popup-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GpPopupDialogComponent implements OnInit {
    text: string;

    constructor(
        public dialogRef: MatDialogRef<GpPopupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { text: string }
    ) { }

    ngOnInit(): void {
        this.text = this.data.text;
    }
}
