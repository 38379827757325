// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    errorsToConsole: true,
    debugOutputs: true,
    apiUrlPartial: 'b2x-api.define.local/api',
    productPricingUrl: 'https://www.definely.com/pricing',
    supportUrl: 'https://definely.com/support',
    definelyUrl: 'https://definely.com',
    definelyLinkedInUrl: 'https://www.linkedin.com/company/trydefinely',
    sentryEnvironment: '',
    sentryDsn: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
