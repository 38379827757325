import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { GpPopupDialogComponent } from './gp-popup-dialog.component';

@NgModule({
    declarations: [GpPopupDialogComponent],
    imports: [CommonModule, AngularMaterialModule],
    exports: [],
    providers: [],
})
export class GpPopupDialogModule { }
