import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { TenantIdService } from "./tenant-id.service";
import { mergeMap, switchMap, tap } from "rxjs/operators";
import { AppsConfiguration } from "../interfaces/configuration.interfaces";
import { LocalStorage_AuthProvider, LocalStorage_TenantName } from "../constants/constants";
import { iif, of } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends BaseService {
    private storage = localStorage;

    constructor(
        httpClient: HttpClient,
        tenantIdService: TenantIdService
    ) {
        super(tenantIdService, httpClient);
    }

    public getAppConfig() {
        return this.tenantIdService.tenantId.pipe(
            mergeMap(tenantInfo => iif(() => this.isConfigCached(tenantInfo.name), 
                of(<AppsConfiguration>{ authProviderUrl: this.storage[LocalStorage_AuthProvider] as string }), 
                this.tenantUrl
                    .pipe(
                        switchMap(url => this.apiGet<AppsConfiguration>(`${url}/configuration/app`)),
                        tap((data) => {
                            this.storage.setItem(LocalStorage_TenantName, tenantInfo.name);
                            this.storage.setItem(LocalStorage_AuthProvider, data.authProviderUrl);
                        })
                    )
                ))
        );
    }

    public isConfigCached(tenantName: string): boolean {
        return this.storage[LocalStorage_AuthProvider] != null && this.storage[LocalStorage_AuthProvider] != undefined &&
            this.storage[LocalStorage_AuthProvider] !== 'undefined' &&
            this.storage[LocalStorage_TenantName] === tenantName;
    }
}