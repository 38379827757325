import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";

@Injectable()
export class TenantIdResolverService implements Resolve<null> {
    constructor(private router: Router) { }

    public resolve(
        route: ActivatedRouteSnapshot
    )
    {
        const tenantId = route.paramMap.get('tenantId');

        if (/[a-z0-9\-]+/.test(tenantId)) {
            console.log('tenant id resolved from path', tenantId);
            return null;
        }
        else {
            this.router.navigate(['404']);
        }
    }
}
